







import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import IFlight from "@/models/Flight";

@Component
export default class Distances extends Vue {
  public flights: IFlight[] = [];

  public fields: object[] = [
    { key: "originAirport", label: "Avresa" },
    { key: "destinationAirport", label: "Resmål" },
    { key: "kilometers", label: "Kilometer" },
    { key: "co2", label: "CO2" },
    { key: "year", label: "År" },
    { key: "month", label: "Månad" },
  ];

  private async getFlights() {
    const fetchedFlights = await adminService.getFlights();

    this.flights = fetchedFlights
      .map((flight) => {
        const date = new Date(flight.createdUtc);
        return {
          ...flight,
          year: date.getFullYear(),
          month: date.getMonth() + 1, // Months are 0-based in JavaScript
        };
      })
      .sort((a: IFlight, b: IFlight) => {
        const nameComparison = a.originAirport.localeCompare(b.originAirport);

        if (nameComparison === 0) {
          // If the names are equal, compare by date
          const dateA = new Date(a.createdUtc);
          const dateB = new Date(b.createdUtc);
          return dateA.getTime() - dateB.getTime();
        } else {
          // Otherwise, compare by name
          return nameComparison;
        }
      });
  }

  public async mounted() {
    await this.getFlights();
  }
}
